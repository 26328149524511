<template>
  <div class="theme-picker">
    <slot></slot>
    <el-drawer
      size="440"
      :visible.sync="drawerVisible"
      :with-header="false">
      <div class="drawer">
        <span class="title">
          <em
            :style="{color:$store.state.currentTheme.primaryColor}"
            class="iconfont icon-yuandianzhong">
          </em>
          {{$t('colorSchemes')}}
        </span>
        <div class="theme-container">
          <div v-for="(item1,index1) in themes"
               :key="index1">
            <div
              :style="{backgroundColor:item1[0].primaryColor}"
              class="color-item">
            </div>
            <div
              :class=" [{active:item2===currentTheme},'theme-item']"
              v-for="(item2,index2) in item1"
              :key="`${index1}-${index2}`"
              @click="checkedTheme(item2)">
            <span
              :style="{backgroundColor:item2.menuBackgroundColor}"
              class="left">
            </span>
              <span class="right">
              <span
                :style="{backgroundColor:item2.barBackgroundColor}"
                class="top">
              </span>
              <span class="main"></span>
            </span>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import themes from './theme-config'

export default {
  name: 'index',
  data () {
    return {
      themes,
      drawerVisible: false,
      currentTheme: null
    }
  },
  mounted () {
    this.$slots.default[0].elm.onclick = () => {
      this.drawerVisible = true
    }
  },
  methods: {
    checkedTheme (item) {
      this.currentTheme = item
      this.$emit('checkedTheme', item)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "index";
</style>
