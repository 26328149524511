/**
 * @description 主题配置文件
 * @author one
 * @method 只需在themeColor的themes数组中配置主题颜色
 * menuBackgroundColor:导航菜单背景颜色
 * menuTextColor:导航菜单字体颜色
 * barBackgroundColor:顶部操作栏背景颜色
 * barTextColor:顶部操作栏字体颜色
 * activeBackgroundColor:导航菜单激活时的颜色
 * logoBackgroundColor:logo区域背景颜色
 * logoTextColor:logo字体颜色
 */

const themeColor = {
  themes: [
    // 默认主题
    {
      themeName: 'custom-default-theme',
      cn_name: '默认主题',
      primaryColor: '#409EFF',
      successColor: '#67C23A',
      warningColor: '#E6A23C',
      dangerColor: '#F56C6C',
      infoColor: '#909399'
    },
    // 青色主题
    {
      themeName: 'custom-cyan-theme',
      cn_name: '青色主题',
      primaryColor: '#009688',
      successColor: '#1E9FFF',
      warningColor: '#FFB800',
      dangerColor: '#FF5722',
      infoColor: '#909399'
    },
    // 藏青色主题
    {
      themeName: 'custom-navy-theme',
      cn_name: '藏青色主题',
      primaryColor: '#4766D9',
      successColor: '#63AEFF',
      warningColor: '#FAA909',
      dangerColor: '#FF4C4E',
      infoColor: '#AAAAAA'
    },
    // 绿色主题
    {
      themeName: 'custom-green-theme',
      cn_name: '绿色主题',
      primaryColor: '#19CAAD',
      successColor: '#67C23A',
      warningColor: '#E6A23C',
      dangerColor: '#F56C6C',
      infoColor: '#909399'
    },
    // 红色主题
    {
      themeName: 'custom-red-theme',
      cn_name: '红色主题',
      primaryColor: '#F56C6C',
      successColor: '#F56C6C',
      warningColor: '#F56C6C',
      dangerColor: '#F56C6C',
      infoColor: '#F56C6C'
    },
    // 紫色主题
    {
      themeName: 'custom-purple-theme',
      cn_name: '紫色主题',
      primaryColor: '#550088',
      successColor: '#00AA00',
      warningColor: '#FFBB00',
      dangerColor: '#FF5511',
      infoColor: '#909399'
    },
    // 蓝色主题
    {
      themeName: 'custom-blue-theme',
      cn_name: '蓝色主题',
      primaryColor: '#0099CC',
      successColor: '#99CC33',
      warningColor: '#E6A23C',
      dangerColor: '#F56C6C',
      infoColor: '#909399'
    },
    // 浅蓝色主题
    {
      themeName: 'custom-blue1-theme',
      cn_name: '浅蓝色主题',
      primaryColor: '#6699CC',
      successColor: '#67C23A',
      warningColor: '#E6A23C',
      dangerColor: '#F56C6C',
      infoColor: '#909399'
    },
    // 深蓝色主题 （默认）
    {
      themeName: 'custom-blue-deep-theme',
      cn_name: '深蓝色主题',
      primaryColor: '#0F376A',
      successColor: '#339933',
      warningColor: '#E6A23C',
      dangerColor: '#CC3333',
      infoColor: '#909399'
    }
  ]
}

// 组合成主题
const themeCombination = []
themeColor.themes.forEach(themeItem => {
  const menuColor = [
    // 白白
    {
      ...themeItem,
      menuBackgroundColor: '#ffffff',
      menuTextColor: '#303133',
      barBackgroundColor: '#ffffff',
      barTextColor: '#303133',
      activeBackgroundColor: themeItem.primaryColor,
      logoBackgroundColor: '#ffffff',
      logoTextColor: '#303133'
    },
    // 白黑
    {
      ...themeItem,
      menuBackgroundColor: '#20222A',
      menuTextColor: '#ffffff',
      barBackgroundColor: '#ffffff',
      barTextColor: '#303133',
      activeBackgroundColor: themeItem.primaryColor,
      logoBackgroundColor: '#20222A',
      logoTextColor: '#ffffff'
    },
    // // 彩彩
    // {
    //   ...themeItem,
    //   menuBackgroundColor: themeItem.primaryColor,
    //   menuTextColor: '#ffffff',
    //   barBackgroundColor: themeItem.primaryColor,
    //   barTextColor: '#ffffff',
    //   activeBackgroundColor: themeItem.primaryColor,
    //   logoBackgroundColor: themeItem.primaryColor,
    //   logoTextColor: '#ffffff'
    // },
    // 彩黑
    {
      ...themeItem,
      menuBackgroundColor: '#20222A',
      menuTextColor: '#ffffff',
      barBackgroundColor: themeItem.primaryColor,
      barTextColor: '#ffffff',
      activeBackgroundColor: themeItem.primaryColor,
      logoBackgroundColor: '#20222A',
      logoTextColor: '#ffffff'
    },
    // 彩白
    {
      ...themeItem,
      menuBackgroundColor: '#ffffff',
      menuTextColor: '#303133',
      barBackgroundColor: themeItem.primaryColor,
      barTextColor: '#ffffff',
      activeBackgroundColor: themeItem.primaryColor,
      logoBackgroundColor: themeItem.primaryColor,
      logoTextColor: '#ffffff'
    }
  ]
  themeCombination.push(menuColor)
})

export default themeCombination
